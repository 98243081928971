.li-card-container {
    display: flex;
    gap: 1rem;

    justify-content: center;

    flex-wrap: wrap;
    margin-bottom: 3rem;
}

.li-card {
    display: flex;
    flex-direction: column;
    gap: 2rem;


    flex-shrink: 0;
    flex-basis: 265px;

    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    background-color: white;
    padding: 15px;
}

@media (max-width: 968px) {
    .li-card {
        flex-basis: 45%;
    }
}

@media (max-width: 568px) {
    .li-card {
        flex-basis: 100%;
        align-items: center;
    }

    .li-card-container {
        margin: 0 2rem 3rem 2rem;
    }
}

.li-card h4 {
    margin: 0;
    padding: 0;
    text-align: left;
    margin-bottom: 8px;
    font-size: 14px;
    color: rgb(51, 51, 51) !important;
}

.li-card h3 {
    margin: 0;
    padding: 0;
}

.li-card ul {
    padding-left: 15px;
}

.li-button-container {
    margin-top: auto;
    min-width: 200px;
}

.li-role-description {
    max-width: 750px;
    margin: auto;
    margin-bottom: 3rem;
}